<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="table"
          title="권한"
          tableId="table"
          :columnSetting="false"
          :usePaging="false"
          :isFullScreen="false"
          :columns="grid.columns"
          :data="grid.data"
          @rowClick="rowClick"
          :filtering="false"
          :isExcelDown="false"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getGrpList" />
            </q-btn-group>
          </template>
          <template slot="table-header-append">
            <c-select
              codeGroupCd="DEVICE_CD"
              codeAttrVal1="Y"
              type="none"
              itemText="codeName"
              itemValue="code"
              name="deviceTypeCd"
              label=""
              v-model="searchParam.deviceTypeCd"
              @input="getGrpList"
            ></c-select>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="griddept"
          title="권한별 부서"
          tableId="griddept"
          :columnSetting="false"
          :usePaging="false"
          :isFullScreen="false"
          :columns="griddept.columns"
          :data="griddept.data"
          :checkClickFlag="false"
          :isExcelDown="false"
          selection="multiple"
          rowKey="deptCd"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="부서추가" icon="add" @btnClicked="addDept" v-if="isSelected && editable" />
              <c-btn label="삭제" icon="remove" @btnClicked="removeDept" v-if="isSelected && editable" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-table
          ref="griduser"
          title="권한별 사용자"
          tableId="griduser"
          :columnSetting="false"
          :usePaging="false"
          :isFullScreen="false"
          :columns="griduser.columns"
          :data="griduser.data"
          :checkClickFlag="false"
          :isExcelDown="false"
          selection="multiple"
          rowKey="userId"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="사용자추가" icon="add" @btnClicked="addUser" v-if="isSelected && editable" />
              <c-btn label="삭제" icon="remove" @btnClicked="removeUser" v-if="isSelected && editable" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'auth-group-user',
  data() {
    return {
      searchParam: {
        defaultFlag: 'N',
        useFlag: 'Y',
        deviceTypeCd: 'P',
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'sysAuthGrpName',
            field: 'sysAuthGrpName',
            label: '권한명',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      griddept: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      griduser: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: false,
          },
          {
            name: 'empNo',
            field: 'empNo',
            label: '사번',
            align: 'center',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '사용자',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      listUrl: '',
      userlistUrl: '',
      deptlistUrl: '',
      deptInsertUrl: '',
      deptDeleteUrl: '',
      userInsertUrl: '',
      userDeleteUrl: '',
      editable: true,
      isSelected: false,
      selectedSysAuthGrpId: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sys.auth.grp.list.url;
      this.userlistUrl = selectConfig.sys.auth.user.list.url;
      this.deptlistUrl = selectConfig.sys.auth.dept.list.url;
      this.deptInsertUrl = transactionConfig.sys.auth.dept.insert.url;
      this.deptDeleteUrl = transactionConfig.sys.auth.dept.delete.url;
      this.userInsertUrl = transactionConfig.sys.auth.user.insert.url;
      this.userDeleteUrl = transactionConfig.sys.auth.user.delete.url;
      this.getGrpList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getGrpList() {
      this.isSelected = false;
      this.getList();
      this.rowRemoveSelect();
      this.griduser.data = [];
      this.griddept.data = [];
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        if (this.$store.getters.user.userId === 'U000000001') {
          this.grid.data = _result.data;
        } else {
          this.grid.data = this.$_.filter(_result.data, item => {return item['sysAuthGrpId'] !== 'SAGI000000'});
        }
      },);
    },
    rowClick(row) {
      // 상세조회
      this.isSelected = true;
      this.selectedSysAuthGrpId = row.sysAuthGrpId;
      this.getDeptList();
      this.getUserList();
    },
    getUserList() {
      this.$http.url = this.userlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {sysAuthGrpId: this.selectedSysAuthGrpId}
      this.$http.request((_result) => {
        this.griduser.data = _result.data;
      },);
    },
    getDeptList() {
      this.$http.url = this.deptlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {sysAuthGrpId: this.selectedSysAuthGrpId}
      this.$http.request((_result) => {
        this.griddept.data = _result.data;
      },);
    },
    addDept() {
      this.popupOptions.title = '부서 추가';
      this.popupOptions.param = {}
      this.popupOptions.target = () => import(`${'@/pages/common/dept/deptMultiPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeDept;
    },
    closeDept(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.griddept.data, { deptCd: item.deptCd }) === -1) {
            saveData.push({
              sysAuthGrpId: this.selectedSysAuthGrpId,
              deptCd: item.deptCd,
            });
          }
        });

        this.$http.url = this.deptInsertUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getDeptList();
        });
      }
    },
    addUser() {
      this.popupOptions.title = '사용자 추가'; // 사고자 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.griduser.data, { userId: item.userId }) === -1) {
            saveData.push({
              sysAuthGrpId: this.selectedSysAuthGrpId,
              userId: item.userId,
            });
          }
        });

        this.$http.url = this.userInsertUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getUserList();
        });
      }
    },
    removeDept() {
      let selectData = this.$refs['griddept'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deptDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.getDeptList();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    removeUser() {
      let selectData = this.$refs['griduser'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.userDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.getUserList();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
